<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      Tables
      <router-link slot="right" to="/testimony/new" class="button">
        New Testimony
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <!-- <notification class="is-info">
        <div>
          <b-icon icon="buffer" custom-size="default"/>
          <b>Sorted and paginated table.</b>&nbsp;Based on Buefy's table.
        </div>
      </notification> -->

      <card-component class="has-table has-mobile-sort-spaced" title="testimonies" icon="account-multiple">
        <TestimoniesTable :data-url="`${$router.options.base}data-sources/testimonies.json`" :checkable="false"/>
      </card-component>

      <hr>

      <!-- <notification class="is-info">
        <div>
          <b-icon icon="buffer" custom-size="default"/>
          <b>Tightly wrapped</b> &mdash; table header becomes card header
        </div>
      </notification> -->

      <!-- <card-component class="has-table has-mobile-sort-spaced">
        <MetricTable :data-url="`${$router.options.base}data-sources/metric.json`" :checkable="true"/>
      </card-component> -->

      <hr>

      <!-- <notification class="is-info">
        <div>
          <b-icon icon="buffer" custom-size="default"/>
          <b>Empty table.</b> When there's nothing to show
        </div>
      </notification> -->

      <!-- <card-component class="has-table has-thead-hidden">
        <MetricTable/>
      </card-component> -->
    </section>
  </div>

</template>

<script>
// import Notification from '@/components/Notification'
import TestimoniesTable from '@/views/Testimonies/TestimoniesTable.vue'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'Testimonies',
  components: { HeroBar, TitleBar, CardComponent, TestimoniesTable },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Testimonies'
      ]
    }
  }
}
</script>
