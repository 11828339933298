import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import Tables from '@/views/Tables.vue'
import Profile from '@/views/Profile.vue'
import Forms from '@/views/Forms.vue'

import Login from '@/views/Login.vue'

import Experts from '@/views/Experts/Experts.vue'
import ExpertForm from '@/views/Experts/ExpertForm.vue'

import Faq from '@/views/Faqs/Faq.vue'
import FaqForm from '@/views/Faqs/FaqForm.vue'

import Metrics from '@/views/Metrics/Metrics.vue'
import MetricForm from '@/views/Metrics/MetricForm.vue'

import Partners from '@/views/Partners/Partners.vue'
import PartnerForm from '@/views/Partners/PartnerForm.vue'

import Testimonies from '@/views/Testimonies/Testimonies.vue'
import TestimonyCreate from '@/views/Testimonies/TestimonyCreate.vue'

import program from '@/views/Programs/Programs.vue'
import programForm from '@/views/Programs/ProgramForm.vue'

import startups from '@/views/Startups/Startups.vue'
import startupsForm from '@/views/Startups/StartupsForm.vue'

import programType from '@/views/ProgramTypes/ProgramTypes.vue'
import ProgramTypeForm from '@/views/ProgramTypes/ProgramTypeForm.vue'

import programMetrics from '@/views/ProgramMetrics/ProgramMetrics.vue'
import ProgramMetricsForm from '@/views/ProgramMetrics/ProgramMetricsForm.vue'

import Blogs from '@/views/Blogs/Blogs.vue'
import BlogForm from '@/views/Blogs/BlogForm.vue'

Vue.use(VueRouter)

const routes = [
  // Login
  {
    meta: {
      title: 'Login'
    },
    path: '/login',
    name: 'login',
    component: Login
  },
  // Home
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Dashboard'
    },
    path: '/',
    name: 'home',
    component: Home
  },
  // Experts
  {
    meta: {
      title: 'Experts'
    },
    path: '/expert',
    name: 'experts',
    component: Experts
  },
  {
    meta: {
      title: 'New Expert'
    },
    path: '/expert/new',
    name: 'expert.new',
    component: ExpertForm
  },
  {
    meta: {
      title: 'Edit Expert'
    },
    path: '/expert/:id',
    name: 'expert.edit',
    component: ExpertForm,
    props: true
  },
  // FAQ
  {
    meta: {
      title: 'FAQs'
    },
    path: '/faq',
    name: 'faq',
    component: Faq
  },
  {
    meta: {
      title: 'New FAQ'
    },
    path: '/faq/new',
    name: 'faq.new',
    component: FaqForm
  },
  {
    meta: {
      title: 'Edit FAQ'
    },
    path: '/faq/:id',
    name: 'faq.edit',
    component: FaqForm,
    props: true
  },
  // Metrics
  {
    meta: {
      title: 'Metrics'
    },
    path: '/metric',
    name: 'metrics',
    component: Metrics
  },
  {
    meta: {
      title: 'New Metric'
    },
    path: '/metric/new',
    name: 'metric.new',
    component: MetricForm
  },
  {
    meta: {
      title: 'Edit Metric'
    },
    path: '/metric/:id',
    name: 'metric.edit',
    component: MetricForm,
    props: true
  },
  // Partners
  {
    meta: {
      title: 'Partners'
    },
    path: '/partner',
    name: 'partner',
    component: Partners
  },
  {
    meta: {
      title: 'New Partner'
    },
    path: '/partner/new',
    name: 'partner.new',
    component: PartnerForm
  },
  {
    meta: {
      title: 'Edit Partner'
    },
    path: '/partner/:id',
    name: 'partner.edit',
    component: PartnerForm,
    props: true
  },
  // Testimonies
  {
    meta: {
      title: 'Testimonies'
    },
    path: '/testimony',
    name: 'testimony',
    component: Testimonies
  },
  {
    meta: {
      title: 'New Testimony'
    },
    path: '/testimony/new',
    name: 'testimony.new',
    component: TestimonyCreate
  },
  {
    meta: {
      title: 'Edit Testimony'
    },
    path: '/testimony/:id',
    name: 'testimony.edit',
    component: TestimonyCreate,
    props: true
  },
  //  programsType
  {
    meta: {
      title: 'ProgramsType'
    },
    path: '/program/type',
    name: 'programtype',
    component: programType
  },
  {
    meta: {
      title: 'New Programs Type'
    },
    path: '/program/type/new',
    name: 'programtype.new',
    component: ProgramTypeForm
  },
  {
    meta: {
      title: 'Edit Programs Type'
    },
    path: '/program/type/:id',
    name: 'programtype.edit',
    component: ProgramTypeForm,
    props: true
  },
  //  programsMetrics
  {
    meta: {
      title: 'ProgramsMetrics'
    },
    path: '/program/metric',
    name: 'programmetric',
    component: programMetrics
  },
  {
    meta: {
      title: 'New Programs Metrics'
    },
    path: '/program/metric/new',
    name: 'programmetric.new',
    component: ProgramMetricsForm
  },
  {
    meta: {
      title: 'Edit Programs Metrics'
    },
    path: '/program/metric/:id',
    name: 'programmetric.edit',
    component: ProgramMetricsForm,
    props: true
  },
  //  programs
  {
    meta: {
      title: 'Programs'
    },
    path: '/program',
    name: 'program',
    component: program
  },
  {
    meta: {
      title: 'New Programs'
    },
    path: '/program/new',
    name: 'program.new',
    component: programForm
  },
  {
    meta: {
      title: 'Edit Programs'
    },
    path: '/program/:id',
    name: 'program.edit',
    component: programForm,
    props: true
  },
  //  Startups
  {
    meta: {
      title: 'Startups'
    },
    path: '/startup',
    name: 'startup',
    component: startups
  },
  {
    meta: {
      title: 'New Startups'
    },
    path: '/startup/new',
    name: 'startup.new',
    component: startupsForm
  },
  {
    meta: {
      title: 'Edit Startups'
    },
    path: '/startup/:id',
    name: 'startup.edit',
    component: startupsForm,
    props: true
  },
  //  Blogs
  {
    meta: {
      title: 'Blogs'
    },
    path: '/blog',
    name: 'blogs',
    component: Blogs
  },
  {
    meta: {
      title: 'New Blog'
    },
    path: '/blog/new',
    name: 'blog.new',
    component: BlogForm
  },
  {
    meta: {
      title: 'Edit Blog'
    },
    path: '/blog/:id',
    name: 'blog.edit',
    component: BlogForm,
    props: true
  },
  // Based on original code
  {
    meta: {
      title: 'Tables'
    },
    path: '/tables',
    name: 'tables',
    component: Tables
  },
  {
    meta: {
      title: 'Forms'
    },
    path: '/forms',
    name: 'forms',
    component: Forms
  },
  {
    meta: {
      title: 'Profile'
    },
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    meta: {
      title: 'New client'
    },
    path: '/client/new',
    name: 'client.new',
    component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue')
  },
  {
    meta: {
      title: 'Edit client'
    },
    path: '/client/:id',
    name: 'client.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
