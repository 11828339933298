<template>
  <div>
    <progress-modal-box :is-active="isLoading" :data-name="uploading_title"/>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="heroRouterLinkTo" class="button">
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component :title="formCardTitle" icon="account-edit" class="tile is-child">
          <form @submit.prevent="submit">
            <b-field label="Index" horizontal>
              <b-input placeholder="1, 2, 3 ...." v-model="form.index"  required />
            </b-field>
            <hr>
            <b-field label="Page" message="Home, Programs, Partner or Place" horizontal>
              <b-select placeholder="Select a page" v-model="form.page" required>
                <option v-for="page_option in page_options" :key="page_option" :value="page_option">{{ page_option }}</option>
              </b-select>
            </b-field>
            <b-field label="Question" message="Question" horizontal>
              <b-input placeholder="Can we?" v-model="form.question" required />
            </b-field>
            <b-field label="Answer" message="Answer" horizontal>
              <b-input placeholder="e.g. Yes we can" v-model="form.answer" required />
            </b-field>
            <!-- <b-field label="Created" horizontal>
              <b-datepicker
                @input="input"
                v-model="form.created_date"
                placeholder="Click to select..."
                icon="calendar-today">
              </b-datepicker>
            </b-field> -->
            <hr>
            <b-field horizontal>
              <b-button type="is-primary" :loading="isLoading" native-type="submit">Submit</b-button>
            </b-field>
          </form>
        </card-component>
        <card-component v-if="isProfileExists" title="Faq Content" icon="account" class="tile is-child">
          <b-field label="Index">
            <b-input :value="form.index" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Page">
            <b-input :value="form.page" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Question">
            <b-input :value="form.question" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Answer">
            <b-input :value="form.answer" custom-class="is-static" readonly/>
          </b-field>
          <!-- <b-field label="Created">
            <b-input :value="createdReadable" custom-class="is-static" readonly/>
          </b-field> -->
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { db, storage } from '@/firebase'
import dayjs from 'dayjs'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import ProgressModalBox from '@/components/ProgressModalBox'

const faq = db.collection('FAQ')

export default {
  name: 'FaqForm',
  components: { CardComponent, Tiles, HeroBar, TitleBar, ProgressModalBox },
  props: {
    id: {
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      uploading_title: null,
      page_options: ['Home', 'Place', 'People', 'Programs'],
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: true
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'New'
      }

      return [
        'Admin',
        'FAQ',
        lastCrumb
      ]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Create FAQ'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'faq.new' }
      } else {
        return { name: 'faq' }
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'New FAQ'
      } else {
        return 'Back'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit FAQ'
      } else {
        return 'New FAQ'
      }
    }
  },
  methods: {
    getClearFormObject () {
      return {
        id: null,
        page: null,
        question: null,
        answer: null,
        index: null,
        created_date: new Date(),
        created_mm_dd_yyyy: null
      }
    },
    input (v) {
      this.createdReadable = dayjs(v).format('MMM D, YYYY')
    },
    submit () {
      this.writeDB()
    },
    writeDB () {
      this.isLoading = true
      this.uploading_title = 'Form data'
      if (this.isProfileExists) {
        console.log('edit faq')
        db.collection('FAQ')
          .doc(this.id)
          .set(this.form)
          .then(() => {
            console.log('faq updated!')
            this.isLoading = false
          })
      } else {
        console.log('nambahin faq')
        db.collection('FAQ')
          .add(this.form)
          .then(() => {
            console.log('faq added!')
            this.isLoading = false
            this.$router.push({ name: 'faq' })
          })
      }
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (id) {
        this.isProfileExists = false
        if (id) {
          this.$bind('form', faq.doc(id)).then(user => {
            this.isProfileExists = true
          })
        } else {
          this.form = this.getClearFormObject()
        }
      }
    }
  }
}
</script>
