<template>
  <div id="app">
    <nav-bar/>
    <aside-menu :menu="menu" @menu-click="menuClick"/>
    <router-view/>
    <footer-bar/>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'

export default {
  name: 'home',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  computed: {
    ...mapGetters(['getUserName', 'isUserAuth']),
    menu () {
      return [
        'General',
        [
          {
            to: '/',
            icon: 'desktop-mac',
            label: 'Dashboard'
          }
        ],
        'Database',
        [
          {
            to: '/expert',
            label: 'Experts',
            icon: 'table',
            updateMark: false
          },
          {
            to: '/faq',
            label: 'FAQ',
            icon: 'table',
            updateMark: false
          },
          {
            to: '/metric',
            label: 'Metrics',
            icon: 'table',
            updateMark: false
          },
          {
            to: '/partner',
            label: 'Partners',
            icon: 'table',
            updateMark: false
          },
          {
            label: 'Programs',
            subLabel: 'Program related data',
            icon: 'view-list',
            menu: [
              {
                to: '/program',
                label: 'Data',
                icon: 'table',
                updateMark: false
              },
              {
                to: '/program/type',
                label: 'Types',
                icon: 'table',
                updateMark: false
              },
              {
                to: '/program/metric',
                label: 'Metrics',
                icon: 'table',
                updateMark: false
              }
            ]
          },
          {
            to: '/startup',
            label: 'Startups',
            icon: 'table',
            updateMark: false
          },
          {
            to: '/testimony',
            label: 'Testimonies',
            icon: 'table',
            updateMark: false
          },
          {
            to: '/blog',
            label: 'Blogs',
            icon: 'table',
            updateMark: false
          }
        ]
        // 'Examples',
        // [
        //   {
        //     action: 'dark-mode-toggle',
        //     label: 'Dark / White',
        //     icon: 'weather-night'
        //   },
        //   {
        //     to: '/tables',
        //     label: 'Tables',
        //     icon: 'table',
        //     updateMark: true
        //   },
        //   {
        //     to: '/forms',
        //     label: 'Forms',
        //     icon: 'square-edit-outline'
        //   },
        //   {
        //     to: '/profile',
        //     label: 'Profile',
        //     icon: 'account-circle'
        //   },
        //   {
        //     label: 'Submenus',
        //     subLabel: 'Submenus Example',
        //     icon: 'view-list',
        //     menu: [
        //       {
        //         href: '#void',
        //         label: 'Sub-item One'
        //       },
        //       {
        //         href: '#void',
        //         label: 'Sub-item Two'
        //       }
        //     ]
        //   }
        // ],
        // 'About',
        // [
        //   {
        //     href: 'https://admin-null.justboil.me',
        //     label: 'Premium Demo',
        //     icon: 'credit-card'
        //   },
        //   {
        //     href: 'https://justboil.me/bulma-admin-template/null',
        //     label: 'About',
        //     icon: 'help-circle'
        //   }
        // ]
      ]
    }
  },
  methods: {
    ...mapActions(['checkAuthAction']),
    menuClick (item) {
      if (item.action && item.action === 'dark-mode-toggle') {
        this.$store.commit('darkModeToggle')
      }
    }
  },
  mounted () {
    this.checkAuthAction()
  }
}
</script>
