<template>
  <div>
    <progress-modal-box :is-active="isLoading" :data-name="uploading_title"/>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="heroRouterLinkTo" class="button">
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component :title="formCardTitle" icon="account-edit" class="tile is-child">
          <form @submit.prevent="submit">
            <!-- <b-field label="ID" horizontal>
              <b-input v-model="form.id" custom-class="is-static" readonly />
            </b-field>
            <hr> -->
            <b-field label="Image" horizontal>
              <file-picker @input="filechosen"/>
            </b-field>
            <hr>
            <b-field label="Name" message="name" horizontal>
              <b-input placeholder="e.g. John Doe" v-model="form.name" required />
            </b-field>
            <b-field label="Title" message="Title" horizontal>
              <b-input placeholder="e.g. John Doe" v-model="form.title" required />
            </b-field>
            <b-field label="Company" message="Company name" horizontal>
              <b-input placeholder="Link Aja" v-model="form.company" required />
            </b-field>
            <b-field label="Header" message="Header" horizontal>
              <b-input placeholder="e.g. Luar biasa" v-model="form.header" required />
            </b-field>
            <b-field label="Body" message="Body" horizontal>
              <b-input placeholder="e.g. Lorem ipsum dolor sit amet" v-model="form.body" required />
            </b-field>
            <b-field label="Page" message="Home or Place" horizontal>
              <b-select placeholder="Select a page" v-model="form.page" required>
                <option v-for="page_option in page_options" :key="page_option" :value="page_option">{{ page_option }}</option>
              </b-select>
            </b-field>
            <!-- <b-field label="Created" horizontal>
              <b-datepicker
                @input="input"
                v-model="form.created_date"
                placeholder="Click to select..."
                icon="calendar-today">
              </b-datepicker>
            </b-field> -->
            <hr>
            <b-field horizontal>
              <b-button type="is-primary" :loading="isLoading" native-type="submit">Submit</b-button>
            </b-field>
          </form>
        </card-component>
        <card-component v-if="isProfileExists" title="Testimony Content" icon="account" class="tile is-child">
          <user-avatar :avatar="form.photo_pic" class="image has-max-width is-aligned-center"/>
          <hr>
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Title">
            <b-input :value="form.title" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Company">
            <b-input :value="form.company" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Header">
            <b-input :value="form.header" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Body">
            <b-input :value="form.body" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Page">
            <b-input :value="form.page" custom-class="is-static" readonly/>
          </b-field>
          <!-- <b-field label="Created">
            <b-input :value="createdReadable" custom-class="is-static" readonly/>
          </b-field> -->
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { db, storage } from '@/firebase'
import dayjs from 'dayjs'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import FilePicker from '@/components/FilePicker'
import UserAvatar from '@/components/UserAvatar'
import ProgressModalBox from '@/components/ProgressModalBox'

const Testimonies = db.collection('Testimonies')

export default {
  name: 'TestimoniesFormEdit',
  components: { UserAvatar, FilePicker, CardComponent, Tiles, HeroBar, TitleBar, ProgressModalBox },
  props: {
    id: {
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      uploading: null,
      uploaded_pic: null,
      uploading_pic: null,
      page_options: ['Home', 'LifeAtKumpul'],
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'Testimonies'
      }

      return [
        'Admin',
        'Testimonies',
        lastCrumb
      ]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Create Testimony'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'testimony.new' }
      } else {
        return { name: 'testimony' }
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'New Testimony'
      } else {
        return 'Back'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Testimony'
      } else {
        return 'New Testimony'
      }
    }
  },
  methods: {
    getClearFormObject () {
      return {
        id: null,
        name: null,
        header: null,
        body: null,
        company: null,
        page: null,
        title: null,
        photo_pic: null,
        created_date: new Date(),
        created_mm_dd_yyyy: null
      }
    },
    input (v) {
      this.createdReadable = dayjs(v).format('MMM D, YYYY')
    },
    filechosen (file) {
      this.uploaded_pic = file
    },
    submit () {
      if (this.uploaded_pic != null) {
        this.isLoading = true
        this.uploading_pic = 'Testimony photo'
        const storageRef = storage.ref().child('Testimony/' + this.uploaded_pic.name).put(this.uploaded_pic)
        storageRef.on('state_changed', snapshot => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(' progress: ', progress)
        }, error => {
          this.$buefy.snackbar.open({
            message: `Error: ${error.message}`,
            type: 'is-danger',
            queue: false
          })
        }, () => {
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.form.photo_pic = url
            this.isLoading = false
            this.writeDB()
          })
        })
      } else {
        this.writeDB()
      }
    },
    writeDB () {
      this.isLoading = true
      this.uploading_title = 'Form data'
      if (this.isProfileExists) {
        db.collection('Testimonies')
          .doc(this.id)
          .set(this.form)
          .then(() => {
            this.isLoading = false
          })
      } else {
        db.collection('Testimonies')
          .add(this.form)
          .then(() => {
            this.isLoading = false
            this.$router.push({ name: 'testimony' })
          })
      }
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (id) {
        this.isProfileExists = false
        if (id) {
          this.$bind('form', Testimonies.doc(id)).then(user => {
            this.isProfileExists = true
          })
        } else {
          this.form = this.getClearFormObject()
        }
      }
    }
  }
}
</script>
