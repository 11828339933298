import Vue from 'vue'
import firebase from 'firebase/compat/app'
// Import needed firebase modules
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)

// Firebase app config
// const config = {
//   apiKey: process.env.VUE_APP_API_KEY,
//   authDomain: process.env.VUE_APP_AUTH_DOMAIN,
//   databaseURL: process.env.VUE_APP_DATABASE_URL,
//   projectId: process.env.VUE_APP_PROJECT_ID,
//   storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
//   appId: process.env.VUE_APP_APP_ID
// }

const config = {
  apiKey: 'AIzaSyCFH0EEmlKtIVH2H4pJLnUFjDb7ryOwR0k',
  authDomain: 'website-kumpul.firebaseapp.com',
  databaseURL: 'https://website-kumpul-default-rtdb.firebaseio.com',
  projectId: 'website-kumpul',
  storageBucket: 'website-kumpul.appspot.com',
  messagingSenderId: '866557114536',
  appId: '1:866557114536:web:a3dbe2c12caf3ccddaea9d'
}

// Init our firebase app
const firebaseApp = firebase.initializeApp(config)

export const db = firebaseApp.firestore()
export const storage = firebaseApp.storage()

const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

db.settings({ timestampsInSnapshots: true })
