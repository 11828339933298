<template>
  <div>
    <progress-modal-box :is-active="isLoading" :data-name="uploading_title"/>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="heroRouterLinkTo" class="button">
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component :title="formCardTitle" icon="account-edit" class="tile is-child">
          <form @submit.prevent="submit">
            <!-- <b-field label="ID" horizontal>
              <b-input v-model="form.id" custom-class="is-static" readonly />
            </b-field>
            <hr> -->
            <b-field label="Title " message="Question" horizontal>
              <b-input placeholder="Can we?" v-model="form.title" required />
            </b-field>
            <b-field label="Description" message="Answer" horizontal>
              <b-input placeholder="e.g. Yes we can" v-model="form.desc" required />
            </b-field>
            <hr>
            <b-field horizontal>
              <b-button type="is-primary" :loading="isLoading" native-type="submit">Submit</b-button>
            </b-field>
          </form>
        </card-component>
        <card-component v-if="isDataExists" title="Type Content" icon="account" class="tile is-child">
          <b-field label="Title">
            <b-input :value="form.title" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Description">
            <b-input :value="form.desc" custom-class="is-static" readonly/>
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { db, storage } from '@/firebase'
import dayjs from 'dayjs'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import ProgressModalBox from '@/components/ProgressModalBox'

const ProgramTypes = db.collection('ProgramTypes')

export default {
  name: 'Program Type Form',
  components: { CardComponent, Tiles, HeroBar, TitleBar, ProgressModalBox },
  props: {
    id: {
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      uploading_title: null,
      form: this.getClearFormObject(),
      createdReadable: null,
      isDataExists: true
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isDataExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'New'
      }

      return [
        'Admin',
        'Program Type',
        lastCrumb
      ]
    },
    heroTitle () {
      if (this.isDataExists) {
        return this.form.title
      } else {
        return 'Create Program Type'
      }
    },
    heroRouterLinkTo () {
      if (this.isDataExists) {
        return { name: 'programtype.new' }
      } else {
        return { name: 'programtype' }
      }
    },
    heroRouterLinkLabel () {
      if (this.isDataExists) {
        return 'New Program Type'
      } else {
        return 'Back'
      }
    },
    formCardTitle () {
      if (this.isDataExists) {
        return 'Edit Program Type'
      } else {
        return 'New Program Type'
      }
    }
  },
  methods: {
    getClearFormObject () {
      return {
        id: null,
        title: null,
        desc: null,
        created_date: new Date(),
        created_mm_dd_yyyy: null
      }
    },
    input (v) {
      this.createdReadable = dayjs(v).format('MMM D, YYYY')
    },
    submit () {
      this.writeDB()
    },
    writeDB () {
      this.isLoading = true
      this.uploading_title = 'Form data'
      if (this.isDataExists) {
        db.collection('ProgramTypes')
          .doc(this.id)
          .set(this.form)
          .then(() => {
            this.isLoading = false
          })
      } else {
        db.collection('ProgramTypes')
          .add(this.form)
          .then(() => {
            this.isLoading = false
            this.$router.push({ name: 'programtype' })
          })
      }
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (id) {
        this.isDataExists = false
        if (id) {
          this.$bind('form', ProgramTypes.doc(id)).then(user => {
            this.isDataExists = true
          })
        } else {
          this.form = this.getClearFormObject()
        }
      }
    }
  }
}
</script>
