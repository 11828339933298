<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      Programs Metrics
      <router-link slot="right" to='/program/metric/new' class="button">
        New Program Metric
      </router-link>
    </hero-bar>
    <section class="section is-main-section">

      <card-component class="has-table has-mobile-sort-spaced" title="Program Metric" icon="account-multiple">
        <ProgramsMetricsTable :checkable="false"/>
      </card-component>

    </section>
  </div>

</template>

<script>
import ProgramsMetricsTable from '@/views/ProgramMetrics/ProgramMetricsTable'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'ProgramsMetrics',
  components: { HeroBar, TitleBar, CardComponent, ProgramsMetricsTable },
  computed: {
    titleStack () {
      return [
        'Admin',
        'ProgramsMetrics'
      ]
    }
  }
}
</script>
