import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import router from '@/router'

const actions = {
  // for checking auth status
  checkAuthAction ({ commit }) {
    return firebase
      .auth()
      .onAuthStateChanged(user => {
        if (user) {
          commit('user', user)
          router.replace({ name: 'home' })
        } else {
          commit('user', null)
          router.replace({ name: 'login' })
        }
      })
  },
  // for sign in
  signInAction ({ commit }, payload) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .catch(error => {
        commit('error', error.message)
      })
  },
  // for sign out
  signOutAction ({ commit }, payload) {
    return firebase
      .auth()
      .signOut()
      .catch(error => {
        commit('error', error.message)
      })
  }
}

export default actions
