<template>
  <b-modal :active.sync="isModalActive" has-modal-card>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Updating...</p>
      </header>
      <section class="modal-card-body">
        <p><b>{{ dataName }}</b> is being updated, please wait..</p>
        <p><b-progress></b-progress></p>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ProgressModalBox',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    dataName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  // methods: {
  //   cancel () {
  //     this.$emit('cancel')
  //   },
  //   confirm () {
  //     this.$emit('confirm')
  //   }
  // },
  watch: {
    isActive (newValue) {
      this.isModalActive = newValue
    }
    // isModalActive (newValue) {
    //   if (!newValue) {
    //     this.cancel()
    //   }
    // }
  }
}
</script>
