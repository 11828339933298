<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      Metrics
      <router-link slot="right" to="/metric/new" class="button">
        New Metric
      </router-link>
    </hero-bar>
    <section class="section is-main-section">

      <card-component class="has-table has-mobile-sort-spaced" title="Metrics" icon="account-multiple">
        <MetricsTable :checkable="false"/>
      </card-component>

    </section>
  </div>

</template>

<script>
import MetricsTable from '@/views/Metrics/MetricsTable.vue'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'Metrics',
  components: { HeroBar, TitleBar, CardComponent, MetricsTable },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Metrics'
      ]
    }
  }
}
</script>
