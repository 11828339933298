<template>
  <div>
    <progress-modal-box :is-active="isLoading" :data-name="uploading_title"/>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="heroRouterLinkTo" class="button">
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component :title="formCardTitle" icon="account-edit" class="tile is-child">
          <form @submit.prevent="submit">
            <!-- <b-field label="ID" horizontal>
              <b-input v-model="form.id" custom-class="is-static" readonly />
            </b-field>
            <hr> -->
            <b-field label="Logo" horizontal>
              <file-picker @input="filechosenLogo"/>
            </b-field>
            <hr>
            <b-field label="Poster" horizontal>
              <file-picker @input="filechosenPoster"/>
            </b-field>
            <hr>
            <b-field label="Name" message="Name" horizontal>
              <b-input placeholder="e.g. John Doe" v-model="form.name" required />
            </b-field>
            <b-field label="Type" message="Choose Program Type" horizontal>
              <b-select placeholder="Select a Type" v-model="form.types" required>
                <option v-for="type_option in types" :key="type_option.id" :value="type_option.id">{{ type_option.title }}</option>
              </b-select>
            </b-field>
            <b-field label="Website" message="website URL" horizontal>
              <b-input placeholder="e.g. https://partner-website.com" v-model="form.website" required />
            </b-field>
            <b-field label="Description" message="Partners's description" horizontal>
              <b-input placeholder="e.g. Lorem ipsum dolor sit amet" v-model="form.desc" required />
            </b-field>
            <b-field label="Tags" message="Partners's tags" horizontal>
              <b-taginput
                v-model="form.tags"
                ellipsis
                icon="label"
                placeholder="Add a tag"
                aria-close-label="Delete this tag">
              </b-taginput>
            </b-field>
            <b-field label="Metrics" message="Metrics" horizontal>
              <b-taginput
                v-model="form.metric"
                ellipsis
                icon="label"
                placeholder="Add a tag"
                aria-close-label="Delete this tag">
              </b-taginput>
            </b-field>
            <b-field label="Video" message="Partners's video url" horizontal>
              <b-input placeholder="e.g. https://youtube.com/link" v-model="form.video" />
            </b-field>
            <b-field label="Page" message="Home or Program" horizontal>
              <b-checkbox v-model="form.page" native-value="Home">
                Home
              </b-checkbox>
              <b-checkbox v-model="form.page" native-value="Program">
                Program
              </b-checkbox>
            </b-field>
            <hr>
            <b-field horizontal>
              <b-button type="is-primary" :loading="isLoading" native-type="submit">Submit</b-button>
            </b-field>
          </form>
        </card-component>
        <card-component v-if="isProfileExists" title="Program Content" icon="account" class="tile is-child">
          <b-field label="Logo">
            <user-avatar :avatar="form.logo" class="image has-max-width is-aligned-center"/>
          </b-field>
          <hr>
          <b-field label="Poster">
            <user-avatar :avatar="form.poster" class="image has-max-width is-aligned-center"/>
          </b-field>
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Program Type">
            <b-input :value="findType(form.types)" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Website">
            <b-input :value="form.website" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Description">
            <b-input :value="form.desc" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Tags">
            <b-input :value="form.tags" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Video">
            <b-input :value="form.video" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Page">
            <b-input :value="form.page" custom-class="is-static" readonly/>
          </b-field>
          <!-- <b-field label="Created">
            <b-input :value="createdReadable" custom-class="is-static" readonly/>
          </b-field> -->
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { db, storage } from '@/firebase'
import dayjs from 'dayjs'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import FilePicker from '@/components/FilePicker'
import UserAvatar from '@/components/UserAvatar'
import ProgressModalBox from '@/components/ProgressModalBox'

const Programs = db.collection('Programs')
const Metrics = db.collection('ProgramMetrics')
const Types = db.collection('ProgramTypes')
export default {
  name: 'ProgramsFormEdit',
  components: { UserAvatar, FilePicker, CardComponent, Tiles, HeroBar, TitleBar, ProgressModalBox },
  props: {
    id: {
      default: null
    }
  },
  data () {
    return {
      types: [],
      //   metrics: [],
      isLoading: false,
      uploading_title_logo: null,
      uploading_title_poster: null,
      uploaded_pic: null,
      uploaded_poster: null,
      page_options: ['Home', 'Program'],
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false
    }
  },
  firestore: {
    // this.form.types: Types
    types: Types
    // metrics: Metrics
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'Programs'
      }

      return [
        'Admin',
        'Programs',
        lastCrumb
      ]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Create Programs'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'program.new' }
      } else {
        return { name: 'program' }
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'New Programs'
      } else {
        return 'Back'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Programs'
      } else {
        return 'New Programs'
      }
    }
  },
  methods: {
    getClearFormObject () {
      return {
        id: null,
        name: null,
        website: null,
        desc: null,
        tags: [],
        video: null,
        page: [],
        logo: null,
        poster: null,
        metric: [],
        types: this.types,
        created_date: new Date(),
        created_mm_dd_yyyy: null
      }
    },
    findType (v) {
      for (let i = 0; i < this.types.length; i++) {
        if (this.types[i].id === v) {
          return this.types[i].title
        }
      }
    },
    input (v) {
      this.createdReadable = dayjs(v).format('MMM D, YYYY')
    },
    filechosenLogo (file) {
      this.uploaded_pic = file
    },
    filechosenPoster (file) {
      this.uploaded_poster = file
    },

    submit () {
      if (this.uploaded_pic != null || this.uploaded_poster != null) {
        if (this.uploaded_pic != null) {
          this.isLoading = true
          this.uploading_title_logo = 'Programs logo'

          const storageRef = storage.ref().child('Programs/Logo/' + this.uploaded_pic.name).put(this.uploaded_pic)
          storageRef.on('state_changed', snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log(' progress: ', progress)
          }, error => {
            this.$buefy.snackbar.open({
              message: `Error: ${error.message}`,
              type: 'is-danger',
              queue: false
            })
          }, () => {
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.form.logo = url
              this.isLoading = false
            })
          })
        } if (this.uploaded_poster != null) {
          this.isLoading = true
          this.uploading_title_poster = 'Programs Posters'

          const storageRef = storage.ref().child('Programs/Posters/' + this.uploaded_poster.name).put(this.uploaded_poster)
          storageRef.on('state_changed', snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log(' progress: ', progress)
          }, error => {
            this.$buefy.snackbar.open({
              message: `Error: ${error.message}`,
              type: 'is-danger',
              queue: false
            })
          }, () => {
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.form.poster = url
              this.isLoading = false
              this.writeDB()
            })
          })
        } this.writeDB()
      } else {
        this.writeDB()
      }
    },
    writeDB () {
      this.isLoading = true
      this.uploading_title = 'Form data'
      if (this.isProfileExists) {
        db.collection('Programs')
          .doc(this.id)
          .set(this.form)
          .then(() => {
            this.isLoading = false
          })
      } else {
        db.collection('Programs')
          .add(this.form)
          .then(() => {
            this.isLoading = false
            this.$router.push({ name: 'program' })
          })
      }
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (id) {
        this.isProfileExists = false
        // Metrics.get().then(querySnapshot => {
        //   const documentsMetric = querySnapshot.docs.map(doc => doc.data())
        //   for (var i = 0; i < documentsMetric.length; i++) {
        //     this.metrics.push(documentsMetric[i].title)
        //   }
        // })
        // types.get().then(querySnapshot => {
        //   const documentstypes = querySnapshot.docs.map(doc => doc.data())
        //   for (var i = 0; i < documentstypes.length; i++) {
        //     this.type.push(documentstypes[i].title)
        //   }
        // })
        if (id) {
          this.$bind('form', Programs.doc(id)).then(user => {
            this.isProfileExists = true
          })
        } else {
          this.form = this.getClearFormObject()
        }
      }
    }
  }
}
</script>
