<template>
  <div>
    <progress-modal-box :is-active="isLoading" :data-name="uploading_title"/>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="heroRouterLinkTo" class="button">
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component :title="formCardTitle" icon="account-edit" class="tile is-child">
          <form @submit.prevent="submit">
            <!-- <b-field label="ID" horizontal>
              <b-input v-model="form.id" custom-class="is-static" readonly />
            </b-field>
            <hr> -->
            <b-field label="Title" message="Title" horizontal>
              <b-input placeholder="e.g. John Doe" v-model="form.title" required />
            </b-field>
            <b-field label="Amount" message="Amount" horizontal>
              <b-input placeholder="100" v-model="form.amount" required />
            </b-field>
            <!-- <b-field label="Created" horizontal>
              <b-datepicker
                @input="input"
                v-model="form.created_date"
                placeholder="Click to select..."
                icon="calendar-today">
              </b-datepicker>
            </b-field> -->
            <hr>
            <b-field horizontal>
              <b-button type="is-primary" :loading="isLoading" native-type="submit">Submit</b-button>
            </b-field>
          </form>
        </card-component>
        <card-component v-if="isProfileExists" title="Metric Content" icon="account" class="tile is-child">
          <b-field label="Title">
            <b-input :value="form.title" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Amount">
            <b-input :value="form.amount" custom-class="is-static" readonly/>
          </b-field>
          <!-- <b-field label="Created">
            <b-input :value="createdReadable" custom-class="is-static" readonly/>
          </b-field> -->
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { db, storage } from '@/firebase'
import dayjs from 'dayjs'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import ProgressModalBox from '@/components/ProgressModalBox'

const metric = db.collection('Metrics')

export default {
  name: 'MetricFormEdit',
  components: { CardComponent, Tiles, HeroBar, TitleBar, ProgressModalBox },
  props: {
    id: {
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      uploading_title: null,
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'New'
      }

      return [
        'Admin',
        'Metric',
        lastCrumb
      ]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Create Metric'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'metric.new' }
      } else {
        return { name: 'metrics' }
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'New Metric'
      } else {
        return 'Back'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Metric'
      } else {
        return 'New Metric'
      }
    }
  },
  methods: {
    getClearFormObject () {
      return {
        id: null,
        title: null,
        amount: null,
        created_date: new Date(),
        created_mm_dd_yyyy: null
      }
    },
    input (v) {
      this.createdReadable = dayjs(v).format('MMM D, YYYY')
    },
    submit () {
      this.writeDB()
    },
    writeDB () {
      this.isLoading = true
      this.uploading_title = 'Form data'
      if (this.isProfileExists) {
        db.collection('Metrics')
          .doc(this.id)
          .set(this.form)
          .then(() => {
            console.log('metric updated!')
            this.isLoading = false
          })
      } else {
        db.collection('Metrics')
          .add(this.form)
          .then(() => {
            console.log('metric added!')
            this.isLoading = false
            this.$router.push({ name: 'metrics' })
          })
      }
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (id) {
        this.isProfileExists = false
        if (id) {
          this.$bind('form', metric.doc(id)).then(user => {
            this.isProfileExists = true
          })
        } else {
          this.form = this.getClearFormObject()
        }
      }
    }
  }
}
</script>
