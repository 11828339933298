<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Login</p>
    </header>
    <section class="modal-card-body">
      <b-notification
        v-for="error in validationErrors"
        :key="error"
        type="is-danger"
        aria-close-label="Close notification"
        role="alert"
        has-icon
        :message="error">
      </b-notification>

      <b-field label="Email">
        <b-input
          type="email"
          v-model="email_value"
          placeholder="Your email"
          required>
        </b-input>
      </b-field>

      <b-field label="Password">
        <b-input
          type="password"
          v-model="password_value"
          password-reveal
          placeholder="Your password"
          required>
        </b-input>
      </b-field>

      <b-checkbox>Remember me</b-checkbox>
    </section>
    <footer class="modal-card-foot">
      <!-- <b-button
        label="Close"
        @click="$parent.close()" /> -->
      <b-button
        label="Login"
        type="is-primary"
        @click="login()" />
    </footer>
  </div>
</template>
<script>
export default {
  name: 'LoginModal',
  props: ['validationErrors'],
  data () {
    return {
      email_value: '',
      password_value: ''
    }
  },
  methods: {
    login () {
      this.$emit('login', this.email_value, this.password_value)
    }
  }
}
</script>
