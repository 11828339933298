<template>
  <div>
    <modal-box :is-active="isModalActive" :trash-object-name="trashObjectName" @confirm="trashConfirm" @cancel="trashCancel"/>
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="title"
      :data="blogs">
        <b-table-column cell-class="has-no-head-mobile is-image-cell" v-slot="props">
        <div class="image">
          <img :src="props.row.thumbnail" class="">
        </div>
      </b-table-column>
       <b-table-column label="Title" field="title" sortable v-slot="props" searchable>
        {{ props.row.title }}
      </b-table-column>
      <b-table-column label="Highlight" field="highlight" sortable v-slot="props" searchable>
        {{ props.row.highlight }}
      </b-table-column>
      <b-table-column label="Body" field="body" sortable v-slot="props" searchable>
        <!-- {{ props.row.body }} -->
        <editor
        v-model="props.row.body"
        :disabled=true
        :init="{
          toolbar:'',
          menubar:'',
          width:600,
          }"
        ></editor>
      </b-table-column>
      <b-table-column label="Author" field="author" sortable v-slot="props" searchable>
        {{ props.row.author }}
      </b-table-column>
      <b-table-column label="Publish_date" field="publish_date" sortable v-slot="props" searchable>
        {{ fixDate(props.row.publish_date) }}
      </b-table-column>
      <b-table-column label="Publish_status" field="publish_status" sortable v-slot="props" searchable>
        {{ props.row.publish_status }}
      </b-table-column>
      <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons is-right">
          <router-link :to="{name:'blog.edit', params: {id: props.row.id}}" class="button is-small is-primary">
            <b-icon icon="account-edit" size="is-small"/>
          </router-link>
          <button class="button is-small is-danger" type="button" @click.prevent="trashModal(props.row)">
            <b-icon icon="trash-can" size="is-small"/>
          </button>
        </div>
      </b-table-column>

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import { db } from '@/firebase'
import ModalBox from '@/components/ModalBox'
import moment from 'moment'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'BlogsTable',
  components: { ModalBox, editor: Editor },
  props: {
    dataUrl: {
      type: String,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    }
  },
  firestore: {
    blogs: db.collection('Blogs')
  },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      blogs: [],
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: [],
      numaric: true,
      searchable: true,
      sortable: true,
      createdReadable: null
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }

      return null
    }
  },
  methods: {
    inputin (v) {
      this.createdReadable = moment(v).format('MM/DD/YYYY')
      return this.createdReadable
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    fixDate (v) {
      return v.toDate()
    },
    trashConfirm () {
      db.collection('Blogs')
        .doc(this.trashObject.id)
        .delete()
      this.isModalActive = false
      this.$buefy.snackbar.open({
        message: 'Confirmed',
        queue: false
      })
    },
    trashCancel () {
      this.isModalActive = false
    }
  }
}
</script>
