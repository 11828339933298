const getters = {
  getUserName (state) {
    return state.userName
  },
  isUserAuth (state) {
    return !!state.userName
  },
  getError (state) {
    return state.errorMessage
  }
}

export default getters
