<template>
  <div>
    <b-modal
      :active="true"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      custom-class="login-modal"
      aria-modal>
      <template #default="props">
        <login-modal :validationErrors="validationErrors" @close="props.close" @login="onLogin"></login-modal>
      </template>
    </b-modal>
  </div>
</template>
<style scoped>
.login-modal.modal-background {
  background-color: rgba(33, 33, 33, 1.0) !important;
}
</style>
<script>
import { mapActions } from 'vuex'
import LoginModal from '@/views/Login/LoginModal.vue'

export default {
  name: 'Login',
  components: {
    LoginModal
  },
  data () {
    return {
      email: '',
      password: '',
      validationErrors: [],
      firebaseError: ''
    }
  },
  methods: {
    ...mapActions(['signInAction']),
    onLogin (_email, _password) {
      this.email = _email
      this.password = _password
      this.validate()
    },
    validate () {
      this.resetError()
      if (!this.email) {
        this.validationErrors.push('<strong>E-mail</strong> cannot be empty')
      }
      if (!this.password) {
        this.validationErrors.push('<strong>Password</strong> cannot be empty')
      }
      if (this.validationErrors.length <= 0) {
        this.signIn()
      }
    },
    resetError () {
      this.validationErrors = []
    },
    signIn () {
      this.signInAction({ email: this.email, password: this.password })
    }
  }
}
</script>
