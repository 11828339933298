const mutations = {
  /* A fit-them-all commit */
  basic (state, payload) {
    state[payload.key] = payload.value
  },

  /* User */
  user (state, payload) {
    console.log('Calling user with state: ', state, ' payload: ', payload)
    if (payload == null) {
      state.userName = null
      state.userEmail = null
      state.userAvatar = null
    } else {
      if (payload.email) {
        state.userName = payload.email
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.photoURL
      }
    }
  },

  /* Aside Mobile */
  asideMobileStateToggle (state, payload = null) {
    const htmlClassName = 'has-aside-mobile-expanded'

    let isShow

    if (payload !== null) {
      isShow = payload
    } else {
      isShow = !state.isAsideMobileExpanded
    }

    if (isShow) {
      document.documentElement.classList.add(htmlClassName)
    } else {
      document.documentElement.classList.remove(htmlClassName)
    }

    state.isAsideMobileExpanded = isShow
  },

  /* Dark Mode */
  darkModeToggle (state, payload = null) {
    const htmlClassName = 'is-dark-mode-active'

    state.isDarkModeActive = !state.isDarkModeActive

    if (state.isDarkModeActive) {
      document.documentElement.classList.add(htmlClassName)
    } else {
      document.documentElement.classList.remove(htmlClassName)
    }
  },

  /* Error */
  error (state, payload) {
    state.errorMessage = payload
  }
}

export default mutations
