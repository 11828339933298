<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      Blogs
      <router-link slot="right" to="/blog/new" class="button">
        New Blog
      </router-link>
    </hero-bar>
    <section class="section is-main-section">

      <card-component class="has-table has-mobile-sort-spaced" title="Blogs" icon="account-multiple">
        <BlogsTable :checkable="false"/>
      </card-component>

    </section>
  </div>

</template>

<script>
import BlogsTable from '@/views/Blogs/BlogsTable'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'Blogs',
  components: { HeroBar, TitleBar, CardComponent, BlogsTable },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Blogs'
      ]
    }
  }
}
</script>
