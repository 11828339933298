<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      Programs
      <router-link slot="right" to="/program/new" class="button">
        New Program
      </router-link>
    </hero-bar>
    <section class="section is-main-section">

      <card-component class="has-table has-mobile-sort-spaced" title="Programs" icon="account-multiple">
        <ProgramsTable :checkable="false"/>
      </card-component>

    </section>
  </div>

</template>

<script>
import ProgramsTable from '@/views/Programs/ProgramsTable'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'Programs',
  components: { HeroBar, TitleBar, CardComponent, ProgramsTable },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Programs'
      ]
    }
  }
}
</script>
