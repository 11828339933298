<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      FAQ
      <router-link slot="right" to="/faq/new" class="button">
        New FAQ
      </router-link>
    </hero-bar>
    <section class="section is-main-section">

      <card-component class="has-table has-mobile-sort-spaced" title="FAQ" icon="account-multiple">
        <FaqTable :checkable="false"/>
      </card-component>

    </section>
  </div>

</template>

<script>
import FaqTable from '@/views/Faqs/FaqTable'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'Faq',
  components: { HeroBar, TitleBar, CardComponent, FaqTable },
  computed: {
    titleStack () {
      return [
        'Admin',
        'FAQ'
      ]
    }
  }
}
</script>
