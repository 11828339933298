<template>
  <div>
    <progress-modal-box :is-active="isLoading" :data-name="uploading_title"/>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="heroRouterLinkTo" class="button">
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component :title="formCardTitle" icon="account-edit" class="tile is-child">
          <form @submit.prevent="submit">
            <!-- <b-field label="ID" horizontal>
              <b-input v-model="form.id" custom-class="is-static" readonly />
            </b-field>
            <hr> -->
            <b-field label="Logo" horizontal>
              <file-picker @input="filechosen"/>
            </b-field>
            <hr>
            <b-field label="Name" message="Partner's name" horizontal>
              <b-input placeholder="e.g. John Doe" v-model="form.name" required />
            </b-field>
            <b-field label="Type" message="Partner's name" horizontal>
              <b-input placeholder="e.g. John Doe" v-model="form.type" required />
            </b-field>
            <b-field label="Website" message="website URL" horizontal>
              <b-input placeholder="e.g. https://partner-website.com" v-model="form.website" required />
            </b-field>
            <b-field label="Description" message="description" horizontal>
              <b-input placeholder="e.g. Lorem ipsum dolor sit amet" v-model="form.desc" required />
            </b-field>
            <b-field label="Tags" message="tags" horizontal>
              <b-taginput
                v-model="form.tags"
                ellipsis
                icon="label"
                placeholder="Add a tag"
                aria-close-label="Delete this tag">
              </b-taginput>
            </b-field>
            <b-field label="Video" message="video url" horizontal>
              <b-input placeholder="e.g. https://youtube.com/link" v-model="form.video" />
            </b-field>
            <!-- <b-field label="Created" horizontal>
              <b-datepicker
                @input="input"
                v-model="form.created_date"
                placeholder="Click to select..."
                icon="calendar-today">
              </b-datepicker>
            </b-field> -->
            <hr>
            <b-field horizontal>
              <b-button type="is-primary" :loading="isLoading" native-type="submit">Submit</b-button>
            </b-field>
          </form>
        </card-component>
        <card-component v-if="isProfileExists" title="Startup Content" icon="account" class="tile is-child">
          <user-avatar :avatar="form.logo" class="image has-max-width is-aligned-center"/>
          <hr>
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Type">
            <b-input :value="form.type" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Website">
            <b-input :value="form.website" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Description">
            <b-input :value="form.desc" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Tags">
            <b-input :value="form.tags" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Video">
            <b-input :value="form.video" custom-class="is-static" readonly/>
          </b-field>
          <!-- <b-field label="Created">
            <b-input :value="createdReadable" custom-class="is-static" readonly/>
          </b-field> -->
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { db, storage } from '@/firebase'
import dayjs from 'dayjs'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import FilePicker from '@/components/FilePicker'
import UserAvatar from '@/components/UserAvatar'
import ProgressModalBox from '@/components/ProgressModalBox'

const Startups = db.collection('Startups')

export default {
  name: 'StartupsFormEdit',
  components: { UserAvatar, FilePicker, CardComponent, Tiles, HeroBar, TitleBar, ProgressModalBox },
  props: {
    id: {
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      uploading_title: null,
      uploaded_pic: null,
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'Startups'
      }

      return [
        'Admin',
        'Startups',
        lastCrumb
      ]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Create Startups'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'startup.new' }
      } else {
        return { name: 'startup' }
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'New Startup'
      } else {
        return 'Back'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Startup'
      } else {
        return 'New Startup'
      }
    }
  },
  methods: {
    getClearFormObject () {
      return {
        id: null,
        name: null,
        type: null,
        website: null,
        desc: null,
        tags: [],
        video: null,
        logo: null,
        created_date: new Date(),
        created_mm_dd_yyyy: null
      }
    },
    input (v) {
      this.createdReadable = dayjs(v).format('MMM D, YYYY')
    },
    filechosen (file) {
      this.uploaded_pic = file
    },
    submit () {
      if (this.uploaded_pic != null) {
        this.isLoading = true
        this.uploading_title = 'Startups logo'

        const storageRef = storage.ref().child('Startups/' + this.uploaded_pic.name).put(this.uploaded_pic)
        storageRef.on('state_changed', snapshot => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(' progress: ', progress)
        }, error => {
          this.$buefy.snackbar.open({
            message: `Error: ${error.message}`,
            type: 'is-danger',
            queue: false
          })
        }, () => {
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.form.logo = url
            this.isLoading = false
            this.writeDB()
          })
        })
      } else {
        this.writeDB()
      }
    },
    writeDB () {
      this.isLoading = true
      this.uploading_title = 'Form data'
      if (this.isProfileExists) {
        db.collection('Startups')
          .doc(this.id)
          .set(this.form)
          .then(() => {
            this.isLoading = false
          })
      } else {
        db.collection('Startups')
          .add(this.form)
          .then(() => {
            this.isLoading = false
            this.$router.push({ name: 'startup' })
          })
      }
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (id) {
        this.isProfileExists = false
        if (id) {
          this.$bind('form', Startups.doc(id)).then(user => {
            this.isProfileExists = true
          })
        } else {
          this.form = this.getClearFormObject()
        }
      }
    }
  }
}
</script>
