<template>
  <div>
    <progress-modal-box :is-active="isLoading" :data-name="uploading_title"/>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="heroRouterLinkTo" class="button">
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component :title="formCardTitle" icon="account-edit" class="tile is-child">
          <form @submit.prevent="submit">
            <b-field label="Title" message="Title" horizontal>
              <b-input placeholder="e.g. John Doe" v-model="form.title" required />
            </b-field>
            <b-field label="Highlight" message="highlight" horizontal>
              <b-input placeholder="e.g. Berton & Steinway" v-model="form.highlight" required />
            </b-field>
            <b-field label="Body" message="Body" horizontal>
              <editor v-model="form.body">
              </editor>
            </b-field>
            <b-field label="Author" message="author" horizontal>
              <b-input placeholder="author" v-model="form.author" required />
            </b-field>
            <b-field label = "publish_status" horizontal>
              <b-switch v-model="form.publish_status"
              true-value="True"
              false-value="False"
              type="is-success">
                  {{ form.publish_status }}
              </b-switch>
            </b-field>
            <hr>
            <b-field label="Thumbnail" horizontal>
              <file-picker @input="filechosen"/>
            </b-field>
            <hr>
            <b-field label="Publish Date" horizontal>
              <b-datepicker
                @input="input"
                v-model="form.publish_date"
                placeholder="Click to select..."
                icon="calendar-today">
              </b-datepicker>
            </b-field>
            <hr>
            <b-field horizontal>
              <b-button type="is-primary" :loading="isLoading" native-type="submit">Submit</b-button>
            </b-field>
          </form>
        </card-component>
        <card-component v-if="isProfileExists" title="Blog Content" icon="account" class="tile is-child">
          <b-field label="Title">
            <b-input :value="form.title" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Body">
            <!-- <b-input :value="form.body" custom-class="is-static" readonly/> -->
            <editor
            v-model="form.body"
            :disabled=true
            :init="{
            toolbar:'',
            menubar:'',
            width:600,
            }"
            ></editor>
          </b-field>
          <b-field label="Highlight">
            <b-input :value="form.highlight" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Author">
            <b-input :value="form.author" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Publish Date">
            <b-input :value="publishedReadable" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Publish Status">
            <b-input :value="form.publish_status" custom-class="is-static" readonly/>
          </b-field>
          <b-field label="Thumbnail">
            <!-- <user-avatar :avatar="form.thumbnail" class="image has-max-width is-aligned-center"/> -->
            <b-image
            :src="form.thumbnail"
            alt="Thumbnail"
            ratio="3by3"
        ></b-image>
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { db, storage, Timestamp } from '@/firebase'
import dayjs from 'dayjs'
import moment from 'moment'
import FilePicker from '@/components/FilePicker'
import UserAvatar from '@/components/UserAvatar'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import ProgressModalBox from '@/components/ProgressModalBox'
import { MarkdownEditor } from 'vue-bulma-markdown-editor'
import Editor from '@tinymce/tinymce-vue'
// import { quillEditor, Quill } from 'vue3-quill'
// import customQuillModule from 'customQuillModule'
// Quill.register('modules/customQuillModule', customQuillModule)

const blogs = db.collection('Blogs')

export default {
  name: 'BlogForm',
  components: { CardComponent, Tiles, HeroBar, TitleBar, ProgressModalBox, FilePicker, editor: Editor },
  props: {
    id: {
      default: null
    }
  },
  data () {
    return {
      form: this.getClearFormObject(),
      isLoading: false,
      uploading_title: null,
      uploaded_pic: null,
      booleans: ['True', 'False'],
      publishedReadable: null,
      isProfileExists: false,
      test_date: new Date()
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'New'
      }

      return [
        'Admin',
        'Blogs',
        lastCrumb
      ]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Create Blogs'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'blog.new' }
      } else {
        return { name: 'blogs' }
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'New Blog'
      } else {
        return 'Back'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Blog'
      } else {
        return 'New Blog'
      }
    }
  },
  methods: {
    getClearFormObject () {
      return {
        highlight: null,
        body: null,
        title: null,
        author: null,
        publish_date: new Date(),
        publish_status: false,
        thumbnail: null
      }
    },
    input (v) {
      this.publishedReadable = dayjs(v).format('MMM D, YYYY')
    },
    filechosen (file) {
      this.uploaded_pic = file
    },
    // inputin () {
    //   // this.publishedReadable = moment(this.form.publish_date).format('MM/DD/YYYY')
    //   this.publishedReadable = new Date()
    //   console.log(typeof this.publishedReadable)
    //   return this.publishedReadable
    // },
    submit () {
      if (this.uploaded_pic != null) {
        this.isLoading = true
        this.uploading_title = 'Thumbnail'

        const storageRef = storage.ref().child('blogs/' + this.uploaded_pic.name).put(this.uploaded_pic)
        storageRef.on('state_changed', snapshot => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(' progress: ', progress)
        }, error => {
          this.$buefy.snackbar.open({
            message: `Error: ${error.message}`,
            type: 'is-danger',
            queue: false
          })
        }, () => {
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.form.thumbnail = url
            this.isLoading = false
            this.writeDB()
          })
        })
      } else {
        this.writeDB()
      }
    },
    writeDB () {
      this.isLoading = true
      this.uploading_title = 'Form data'
      if (this.isProfileExists) {
        db.collection('Blogs')
          .doc(this.id)
          .set(this.form)
          .then(() => {
            this.isLoading = false
          })
      } else {
        db.collection('Blogs')
          .add(this.form)
          .then(() => {
            this.isLoading = false
            this.$router.push({ name: 'blogs' })
          })
      }
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (id) {
        this.isProfileExists = false
        if (id) {
          this.$bind('form', blogs.doc(id)).then(user => {
            var _publishDate = this.form.publish_date
            this.form.publish_date = _publishDate.toDate()
            this.input(this.form.publish_date)
            this.isProfileExists = true
          })
        } else {
          this.form = this.getClearFormObject()
        }
      }
    }
  }
}
</script>
